import * as React from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { EmailForm } from "./ValidateContact";
import axios from "axios";

interface Props {
  setBanner: React.Dispatch<React.SetStateAction<string>>;
  banner: string;
}

export const Contact: React.FC<Props> = ({ setBanner, banner }) => {
  const [text, setText] = React.useState<string[]>([]);
  const [form, setForm] = React.useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  } as EmailForm);

  React.useEffect(() => {
    axios
      .get(`https://headless.jonvickersmedia.com/wp-json/wp/v2/contact`)
      .then((res) => {
        setBanner(res.data[0].acf.banner);
        setText([
          ...text,
          res.data[0].acf.text1,
          res.data[0].acf.text2,
          res.data[0].acf.text3,
        ]);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const submitHandler = () => {
    axios
      .post(`https://api.web3forms.com/submit`)
      .then((res) => {
        setForm({ ...form, name: "", email: "", message: "" });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <Box
        sx={(theme) => ({
          padding: `${theme.palette.custom.xxLarge} ${theme.palette.custom.medium} ${theme.palette.custom.xxLarge} ${theme.palette.custom.medium}`,
          display: "flex",
          flexDirection: "column",
          columnGap: theme.palette.custom.xLarge,
          rowGap: theme.palette.custom.xLarge,
          width: "100%",
        })}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ fontSize: "2rem", textAlign: "center" }}>
            CONNECT WITH JON
          </Typography>
        </Box>
        <Box
          sx={(theme) => ({
            display: "flex",
            columnGap: theme.palette.custom.xLarge,
            padding: `0px ${theme.palette.custom.xxLarge}`,
            [theme.breakpoints.down("md")]: {
              flexDirection: "column-reverse",
              alignItems: "center",
              rowGap: theme.palette.custom.xLarge,
              padding: "0px",
            },
          })}
        >
          <Box
            sx={(theme) => ({
              width: "100%",
            })}
          >
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
                width: "100%",
              }}
              action="https://api.web3forms.com/submit"
              method="POST"
            >
              <input
                type="hidden"
                name="access_key"
                value="748acac5-45c7-4ad5-a080-0fc97dde572d"
                // my key
                // value="cf9222d1-037d-4494-ba84-2a24397ff30c"
              />
              <TextField
                name="Name"
                autoComplete="name"
                // id="outlined-basic"
                label="Name"
                variant="outlined"
                sx={{
                  width: "100%",
                }}
                value={form.name}
                onChange={(e) => {
                  setForm({ ...form, name: e.target.value });
                }}
                required
              />
              <TextField
                name="email"
                // id="outlined-basic"
                label="Email"
                variant="outlined"
                sx={{
                  width: "100%",
                }}
                value={form.email}
                onChange={(e) => {
                  setForm({ ...form, email: e.target.value });
                }}
                required
              />
              <TextField
                multiline
                name="Message"
                // id="outlined-basic"
                label="Message"
                variant="outlined"
                sx={{ width: "100%" }}
                rows={4}
                value={form.message}
                onChange={(e) => {
                  setForm({ ...form, message: e.target.value });
                }}
                required
              />
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Button
                  onClick={() => submitHandler()}
                  type="submit"
                  variant="contained"
                >
                  Send
                </Button>
              </Box>
            </form>
          </Box>
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              rowGap: theme.palette.custom.large,
              [theme.breakpoints.down("md")]: {
                alignItems: "center",
              },
            })}
          >
            <Typography sx={{ fontSize: "1.22rem" }}>{text[0]}</Typography>
            <Typography sx={{ fontSize: "1.22rem" }}>{text[1]}</Typography>
            <Typography sx={{ fontSize: "1.22rem" }}>{text[2]}</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
